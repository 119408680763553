import { AxiosError } from 'axios'
import { useFeedback } from 'context'
import { useAnalytics } from 'hooks/analytics/useAnalytics'
import { useLocalStorage } from 'hooks/localStorage/useLocalStorage'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useCookies } from 'react-cookie'
import { useMutation } from 'react-query'
import {
  AuthenticationServices,
  ROLE,
  TEACHER_TYPES,
  UserGroupServices,
  UserInfoTypes
} from 'services'
import { AuthContextTypes, FeideFetchStatusTypes } from './Auth.context.types'

export const AuthContext = createContext<AuthContextTypes>(
  {} as AuthContextTypes
)

export const useAuth = () => useContext(AuthContext)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DUMMY_USER: UserInfoTypes = {
  id: 17,
  name: 'Dummy User',
  createdAt: new Date('2022-07-04T15:10:48.192Z'),
  updatedAt: new Date('2022-07-04T15:10:48.192Z'),
  roles: [ROLE.SALES_TEAM],
  teacherType: TEACHER_TYPES.MANAGER,
  fusionauthId: '5139c950-bcdd-4ad0-9f2c-d998cd857357',
  fusionauthAccessToken: 'fake-fusion-auth-access-token',
  feideToken: 'fake-feide-auth-access-token'
}

export const AuthContextWrapper: React.FC = ({ children }) => {
  const [user, setUser] = useState<UserInfoTypes | null>(null)
  const [cookies, , removeCookie] = useCookies()
  const { sendLoginSuccess, checkLoginAttempt } = useAnalytics()

  const [isFeideSync, setIsFeideSync] = useLocalStorage<boolean>(
    'isFeideSync',
    false
  )
  const [feideFetchStatus, setFeideFetchStatus] =
    useState<FeideFetchStatusTypes>(null)
  const [hasCookie, setHasCookie] = useState<boolean | null>(null)
  const { alert } = useFeedback()

  const fetchFeide = useMutation(
    '/user-groups/feide/user-group',
    UserGroupServices.feide.put,
    {
      onMutate: () => setFeideFetchStatus('fetching'),
      onSuccess: () => setFeideFetchStatus('success'),
      onError: (error: AxiosError) => {
        setFeideFetchStatus('error')
        alert.showError(error.message)
      }
    }
  )

  const doLogout = useCallback(async () => {
    await AuthenticationServices.logout()

    localStorage.removeItem('user')
    localStorage.removeItem('session_date')
    localStorage.removeItem('isFeideSync')
    removeCookie('access_token', {
      path: '/'
    })
  }, [AuthenticationServices])

  const mutateUserInfo = useMutation(
    '/user/info',
    () => AuthenticationServices.get.userInfo(),
    {
      onSuccess: ({ data: { data: userData } }) => {
        setHasCookie(true)
        setUser(userData)
        if (checkLoginAttempt()) {
          sendLoginSuccess(userData)
        }
        if (
          userData &&
          userData.feideToken &&
          !isFeideSync &&
          feideFetchStatus !== 'fetching'
        ) {
          fetchFeide.mutateAsync(undefined, {
            onSettled: () => setIsFeideSync(true)
          })
        }
      },
      onError: () => {
        localStorage.removeItem('user')
        localStorage.removeItem('session_date')
        localStorage.removeItem('isFeideSync')
        setHasCookie(false)
        setUser(null)
      }
    }
  )

  const checkCookie = useCallback(async () => {
    if (hasCookie === false) {
      return
    }

    if (hasCookie !== true) {
      setHasCookie(null)
    }
    const data = await mutateUserInfo.mutateAsync()
    setHasCookie(Boolean(data))
  }, [hasCookie])

  useEffect(() => {
    checkCookie()
    window.addEventListener('focus', checkCookie)
    return () => {
      window.removeEventListener('focus', checkCookie)
    }
  }, [])

  const auth = useMemo(
    () => ({
      user,
      setUser,
      feideFetchStatus,
      hasCookie,
      checkCookie,
      doLogout
    }),
    [
      setUser,
      user,
      feideFetchStatus,
      setFeideFetchStatus,
      doLogout,
      hasCookie,
      cookies,
      checkCookie
    ]
  )

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}
