export default {
  description:
    'Vi bruker informasjonskapsler for at nettstedet vårt skal fungere riktig, for å forbedre brukervennligheten og for å analysere trafikken vår.',
  readMore: 'Les mer',
  customize: 'Tilpass',
  acceptNecessary: 'Godta kun nødvendige',
  acceptAll: 'Godta alle',
  settings: {
    title: 'Innstillinger for informasjonskapsler',
    save: 'Lagre',
    necessary: {
      title: 'Nødvendige informasjonskapsler',
      description:
        'Disse informasjonskapslene er nødvendige for at nettstedet skal fungere og kan ikke deaktiveres i systemene våre. De aktiveres vanligvis som følge av handlinger du utfører, som å tilpasse personverninnstillinger, logge inn eller fylle ut skjemaer. Informasjonskapslene lagrer ikke informasjon som kan identifisere deg personlig.'
    },
    performance: {
      title: 'Ytelseskapsler',
      description:
        'Disse informasjonskapslene lar oss telle besøk og trafikkilder slik at vi kan måle og forbedre ytelsen til nettstedet vårt. All informasjon disse informasjonskapslene samler inn, er aggregert og derfor anonym. Hvis du ikke tillater disse informasjonskapslene, vet vi ikke når du har besøkt nettstedet vårt, og vi kan ikke overvåke ytelsen.'
    }
  }
}
