import { useCookies } from 'react-cookie'

const cookieName = 'cookie_preferences'

export interface CookiePreferences {
  performance: boolean
}

export const useCookiePreferences = () => {
  const [cookies, setCookie] = useCookies([cookieName])

  const getPreferences = (): CookiePreferences | null => {
    return cookies[cookieName] ?? null
  }
  const savePreferences = (newPreferences: CookiePreferences) => {
    setCookie(cookieName, newPreferences, {
      path: '/',
      maxAge: 365 * 24 * 60 * 60
    })
  }

  return {
    getPreferences,
    savePreferences
  }
}
