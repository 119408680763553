import { api, UserInfoTypes } from '../../api'
import {
  AnalyticsEventDto,
  AnalyticsEventStatus,
  AnalyticsEventTypes
} from './analytics.types'

const analyticsEvent = async <T>(event: AnalyticsEventDto<T>) => {
  api.post(`analytics`, event)
}

const loginSuccess = async (user: UserInfoTypes) => {
  analyticsEvent({
    type: AnalyticsEventTypes.Login,
    status: AnalyticsEventStatus.Success,
    payload: user
  })
}

export default {
  post: {
    loginSuccess,
    analyticsEvent
  }
}
