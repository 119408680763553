export default {
  description:
    'Vi brukar informasjonskapslar for at nettstaden vår skal fungere riktig, for å betre brukarvenlegheita og for å analysere trafikken vår.',
  readMore: 'Les meir',
  customize: 'Tilpass',
  acceptNecessary: 'Godta kun nødvendige',
  acceptAll: 'Godta alle',
  settings: {
    title: 'Innstillingar for informasjonskapslar',
    save: 'Lagre',
    necessary: {
      title: 'Nødvendige informasjonskapslar',
      description:
        'Desse informasjonskapslane er nødvendige for at nettstaden skal fungere og kan ikkje deaktiverast i systema våre. Dei blir vanlegvis aktiverte som følgje av handlingar du utfører, som å tilpasse personverninnstillingar, logge inn eller fylle ut skjema. Informasjonskapslane lagrar ikkje informasjon som kan identifisere deg personleg.'
    },
    performance: {
      title: 'Ytingskapslar',
      description:
        'Desse informasjonskapslane lar oss telje besøk og trafikkkjelder slik at vi kan måle og betre ytinga til nettstaden vår. All informasjon desse informasjonskapslane samlar inn, er aggregert og derfor anonym. Viss du ikkje tillèt desse informasjonskapslane, veit vi ikkje når du har besøkt nettstaden vår, og vi kan ikkje overvake ytinga.'
    }
  }
}
