import auth from './auth'
import general from './general'
import navigation from './navigation'
import components from './components'
import pages from './pages'
import cookieConsent from './cookieConsent'

export const translationEn = {
  auth,
  components,
  general,
  navigation,
  pages,
  cookieConsent
}
