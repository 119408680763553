export default {
  description:
    'We use cookies to make our website work properly, improve usability, and analyze our traffic.',
  readMore: 'Read more',
  customize: 'Customize',
  acceptNecessary: 'Accept Necessary Only',
  acceptAll: 'Accept All',
  settings: {
    title: 'Cookie Settings',
    save: 'Save',
    necessary: {
      title: 'Necessary Cookies',
      description:
        'These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. These cookies do not store any personally identifiable information.'
    },
    performance: {
      title: 'Performance Cookies',
      description:
        'These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance.'
    }
  }
}
