import { useCookiePreferences } from 'hooks/cookiePreferences/useCookiePreferences'
import { AnalyticsServices, UserInfoTypes } from 'services'

const loginAttemptSessionKey = 'loginAttempt'

export const useAnalytics = () => {
  const { getPreferences } = useCookiePreferences()

  // store flag in session storage to determine if page load was the result of a login attempt
  const setLoginAttempt = () => {
    sessionStorage.setItem(loginAttemptSessionKey, 'true')
  }

  const checkLoginAttempt = () => {
    const isAttempt = sessionStorage.getItem(loginAttemptSessionKey) === 'true'
    sessionStorage.removeItem(loginAttemptSessionKey)
    return isAttempt
  }

  const sendLoginSuccess = (user: UserInfoTypes) => {
    if (getPreferences()?.performance) {
      AnalyticsServices.post.loginSuccess(user)
    }
  }

  return {
    sendLoginSuccess,
    setLoginAttempt,
    checkLoginAttempt
  }
}
