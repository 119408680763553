export enum AnalyticsEventTypes {
  Login = 'login'
}

export enum AnalyticsEventStatus {
  Success = 'success'
}

export interface AnalyticsEventDto<T> {
  type: AnalyticsEventTypes
  status: AnalyticsEventStatus
  payload?: T
}
